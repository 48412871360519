/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { css } from 'react-emotion'
import { Box } from '../components/Layout'

import profilePic from '../components/profile-pic.jpg'
import Layout from '../layouts'

const imgStyle = css`
  border-radius: 5px;
  margin-bottom: 1.7rem;
  display: block;
  margintop: 1rem;
  width: 100%;
  height: auto;
`

const About = () => {
  return (
    <Layout>
      <Box>
        <Box
          width={[1, 1, 1 / 2]}
          m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 0 auto']}
          px={[3, 3, 0]}
        >
          <h1>About</h1>
          <img src={profilePic} className={imgStyle} alt="" />
          <p>
            I am a software developer working in the Seattle, WA area. In my
            free time I enjoy cooking, eating, following sports and watching TV
            series.
          </p>
        </Box>
      </Box>
    </Layout>
  )
}

export default About
